import Countdown from 'react-countdown';


export default function CountDown({type, isWhitelist, isPublic, whitelistDate, publicDate, noText=false}) {

    // Countdown renderer  
    const renderer = ({ total, days, hours, minutes, seconds, completed }) => {
        // if (completed)
        //     window.location.replace(window.location.origin + window.location.pathname);
        // else{
            if(noText && noText=='true')
                return  (<span>{days * 24 + hours}H {minutes}M {seconds}S </span>);
            return (<span>{days * 24 + hours}H {minutes}M {seconds}S </span>);
        // }
    };

	// Return countdown
	return countDown();

	// Main Function to render coutndown based on condition  
	function countDown() {
        if(type == 'whitelist'){
            if(isWhitelist) return (<><div>Ended</div></>);
            return (<Countdown date={whitelistDate} renderer={renderer} />);
        }
        else if(type =='publicEnd'){
            return (<Countdown date={1652457599 * 1000} renderer={renderer} />); 
        }
        else {
            if(isPublic) return (<><div>Ended</div></>);
            return (<Countdown date={publicDate} renderer={renderer} />); 
        }

	}

}
