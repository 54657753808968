import { createSlice } from "@reduxjs/toolkit"
import { keyStores } from "near-api-js"

const initialState = {
    env: 'mainnet',
	isWalletConnected: false,
	wallet: null,
	smartContractId: null,
	nearConfig: {
        keyStore: new keyStores.BrowserLocalStorageKeyStore(),
        networkId: "mainnet",
		nodeUrl: "https://rpc.mainnet.near.org",
		walletUrl: "https://wallet.near.org",
		helperUrl: "https://helper.mainnet.near.org",
		explorerUrl: "https://explorer.near.org",
        contractName: "chads_on_near.enleap.near",
	},
    sentiment: {
        s1: {
            msg: "Are you gay ?",
            input: ["👍","👎"]
        },
        s2: {
            msg: "Are you Happy ?",
            input: ["👍","👎"]
        }
    },
	metadata: {
		max_mint_per_transaction: 0,
		max_mint_per_wallet: 0,
		mint_price: 0,
		public_mint_start_time: 1646389130,
		wl_mint_price: 0,
		wl_mint_start_time: 1646389128,
	},
	leftCount: null,
	totalCount: 0,
	wlBlance: 0,
	mintBalance: 0,
	userAccountId: null,
	userBalance: null,
	userPublicKey: null // future
}


// Detect environment based on path 
// if (window.location.href.indexOf("testnet") > -1) {
// if(true){
//     initialState.env ='testnet';
//     initialState.nearConfig = {
// 		keyStore: new keyStores.BrowserLocalStorageKeyStore(),
//         networkId: "testnet",
// 		nodeUrl: "https://rpc.testnet.near.org",
// 		walletUrl: "https://wallet.testnet.near.org",
// 		helperUrl: "https://helper.testnet.near.org",
// 		explorerUrl: "https://explorer.testnet.near.org",
// 		contractName: "62765b369116f.enleap-v1.testnet"
//     };
// }

const connectionSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		setWallet: (state, action) => {
			state.wallet = action.payload
		},
		setIsWalletConnected: (state, action) => {
			state.isWalletConnected = action.payload
		},
		setMetadata: (state, action) => {
			state.metadata = action.payload
		},
		setLeftCount: (state, action) => {
			state.leftCount = action.payload
		},
		setTotalCount: (state, action) => {
			state.totalCount = action.payload
		},
		setWlBalance: (state, action) => {
			state.wlBalance = action.payload
		},
		setMintBalance: (state, action) => {
			state.mintBalance = action.payload
		},
		setUserAccountId: (state, action) => {
			state.userAccountId = action.payload
		},
		setUserBalance: (state, action) => {
			state.userBalance = action.payload
		},
		setSmartContractId: (state, action) => {
			state.smartContractId = action.payload
		},
	}
})

export default connectionSlice.reducer
export const { setUserAccountId, setUserBalance, setMintBalance, setWlBalance, setWallet, setSmartContractId, setIsWalletConnected, setMetadata, setLeftCount, setTotalCount } = connectionSlice.actions